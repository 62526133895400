<template>
  <!-- begin::Home -->
  <div v-if="isAuthenticated && loggedUserRoleID > 0" class="px-lg-48">
    <div class="home-title">
      {{ $t("HOME.WELCOME", { name: loggedUserName }) }}
      <hr class="home-title-sub" />
    </div>

    <HomeObserver v-if="loggedUserRoleID === rolesList.ID_OBSERVER" />

    <HomeSuperadmin v-if="loggedUserRoleID === rolesList.ID_SUPERADMIN" />

    <HomeSender
      v-if="
        loggedUserRoleID === rolesList.ID_SENDER_ADMIN ||
          loggedUserRoleID === rolesList.ID_SENDER_EMPLOYEE
      "
    />

    <HomeTransporter
      v-if="loggedUserRoleID === rolesList.ID_TRANSPORTER_ADMIN"
    />
  </div>
  <!-- end::Home -->
</template>

<script>
import { mapGetters } from "vuex";
import HomeObserver from "@/view/pages/manager/home/HomeObserver.vue";
import HomeSuperadmin from "@/view/pages/manager/home/HomeSuperadmin.vue";
import HomeSender from "@/view/pages/manager/home/HomeSender.vue";
import HomeTransporter from "@/view/pages/manager/home/HomeTransporter.vue";

export default {
  name: "Home",
  components: {
    HomeObserver,
    HomeSuperadmin,
    HomeSender,
    HomeTransporter
  },

  data() {
    return {
      loggedUserRoleID: 0,
      loggedUserName: ""
    };
  },

  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUserName",
      "currentUserRoleID",
      "rolesList"
    ])
  },

  mounted() {
    // Set content by role
    this.loggedUserRoleID = this.currentUserRoleID;
    this.loggedUserName = this.currentUserName;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  }
};
</script>
