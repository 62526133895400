<template>
  <!-- begin::Home for TransporterAdmin Role -->
  <div v-if="isAuthenticated">
    <div class="row">
      <router-link
        v-for="(item, i) in homeItemsList"
        :key="i"
        :to="item.to"
        v-slot="{ href, navigate }"
        custom
      >
        <div class="col-lg-3 text-center p-6">
          <a :href="href" @click="navigate">
            <div
              class="d-flex flex-column justify-content-around align-items-center home-box"
            >
              <inline-svg class="home-icon" :src="item.icon" />
              <h3 class="home-text">
                {{ item.label }}
              </h3>
            </div>
          </a>
        </div>
      </router-link>
    </div>
  </div>
  <!-- end::Home for TransporterAdmin Role -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeTransporter",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      homeItemsList: [
        {
          to: "/manager/myprofile",
          label: this.$i18n.t("MY_PROFILE.TITLE"),
          icon: "/media/icons/perfil-primary.svg"
        },
        {
          to: "/manager/fares",
          label: this.$i18n.t("MENU.FARES"),
          icon: "/media/icons/tarifas-primary.svg"
        },
        {
          to: "/manager/services",
          label: this.$i18n.t("MENU.SERVICES"),
          icon: "/media/icons/servicios-extra-primary.svg"
        },
        {
          to: "/manager/trucks",
          label: this.$i18n.t("MENU.FLEET"),
          icon: "/media/icons/truck-primary.svg"
        },
        {
          to: "/manager/drivers",
          label: this.$i18n.t("MENU.DRIVERS"),
          icon: "/media/icons/choferes-primary.svg"
        },
        {
          to: "/manager/offers",
          label: this.$i18n.t("MENU.OFFERS_RECEIVED"),
          icon: "/media/icons/ofertas-primary.svg"
        },
        {
          to: "/manager/shipments",
          label: this.$i18n.t("MENU.SHIPMENTS"),
          icon: "/media/icons/envios-primary.svg"
        }
      ]
    };
  }
};
</script>
