<template>
  <!-- begin::Home for SenderAdmin & SenderEmployee Roles -->
  <div v-if="isAuthenticated">
    <div class="row mt-0 mb-6">
      <div class="col-lg-12 text-center">
        <p class="text-white">
          {{ $t("PRICE_SEARCH.QUESTION") }}
        </p>
        <button
          class="btn btn-pill button-form btn-tertiary px-9"
          @click="startNewPriceSearch"
        >
          <inline-svg src="/media/icons/tarifas.svg" class="mr-3" />
          {{ $t("PRICE_SEARCH.GO_TO_SEARCH_2") }}
        </button>
      </div>
    </div>

    <div class="row">
      <router-link
        v-for="(item, i) in homeItemsList"
        :key="i"
        :to="item.to"
        v-slot="{ href, navigate }"
        custom
      >
        <div
          v-if="
            !item.requiresPremiumSubscription || currentUserCompanyIsPremium
          "
          class="col-lg-4 text-center p-6"
        >
          <a :href="href" @click="navigate">
            <div
              class="d-flex flex-column justify-content-around align-items-center home-box"
            >
              <inline-svg class="home-icon" :src="item.icon" />
              <h3 class="home-text">
                {{ item.label }}
              </h3>
            </div>
          </a>
        </div>
      </router-link>
    </div>

    <div v-if="!currentUserCompanyIsPremium" class="row my-3 mb-9">
      <div class="col-lg-12 text-center">
        <p class="text-white">
          {{ $t("HOME.UPGRADE") }}
        </p>
        <button
          class="btn btn-pill btn-tertiary button-form"
          @click="$router.push('/manager/mysubscription')"
        >
          {{ $t("MY_COMPANY.CHANGE_SUBSCRIPTION") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Home for SenderAdmin & SenderEmployee Roles -->
</template>

<script>
import { mapGetters } from "vuex";

import PriceSearchService from "@/core/services/api/v2/pricesearch.service";

export default {
  name: "HomeSender",
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserCompanyIsPremium"])
  },

  data() {
    return {
      homeItemsList: [
        {
          to: "/manager/myprofile",
          label: this.$i18n.t("MY_PROFILE.TITLE"),
          icon: "/media/icons/perfil-primary.svg",
          requiresPremiumSubscription: false
        },
        {
          to: "/manager/invoicecenters",
          label: this.$i18n.t("MENU.INVOICE_CENTERS"),
          icon: "/media/icons/facturacion-primary.svg",
          requiresPremiumSubscription: true
        },
        {
          to: "/manager/addresses",
          label: this.$i18n.t("MENU.FREQUENT_ADDRESSES"),
          icon: "/media/icons/pin-2-primary.svg",
          requiresPremiumSubscription: true
        },
        {
          to: "/manager/myvehicles",
          label: this.$i18n.t("MENU.MY_VEHICLES"),
          icon: "/media/icons/car-primary.svg",
          requiresPremiumSubscription: true
        },
        {
          to: "/manager/offers",
          label: this.$i18n.t("MENU.OFFERS"),
          icon: "/media/icons/ofertas-primary.svg",
          requiresPremiumSubscription: false
        },
        {
          to: "/manager/shipments",
          label: this.$i18n.t("MENU.SHIPMENTS"),
          icon: "/media/icons/envios-primary.svg",
          requiresPremiumSubscription: false
        }
      ]
    };
  },

  methods: {
    startNewPriceSearch() {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch");
    }
  }
};
</script>
