<template>
  <!-- begin::Home for Observer Role -->
  <div v-if="isAuthenticated">
    <div class="text-center">
      <router-link to="" v-slot="{ href, navigate }" custom>
        <a :href="href" @click="navigate">
          <div
            class="d-flex flex-column justify-content-around align-items-center home-observer-box"
          >
            <img src="/media/bg/rectangle.jpg" class="home-img" />
            <div
              class="d-flex flex-row justify-content-between align-items-center py-9 px-12"
            >
              <img src="/media/icons/group.png" class="pr-3" />
              <h3 class="home-observer-text">
                {{ $t("HOME.OBSERVER_DESC") }}
              </h3>
            </div>
            <div class="w-100 text-center pt-3 pb-12">
              <button
                class="btn btn-pill btn-tertiary"
                style="text-transform: uppercase"
                @click="goToShipments"
              >
                {{ $t("SHIPMENTS.VIEW_SHIPMENT") }}
              </button>
            </div>
          </div>
        </a>
      </router-link>
    </div>
  </div>
  <!-- end::Home for Observer Role -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeObserver",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },

  methods: {
    goToShipments(e) {
      e.preventDefault();
      this.$router.push("/manager/shipments").catch(() => {
        // To avoid some ugly messages about redirect
        // console.info(error.message);
      });
    }
  }
};
</script>
