<template>
  <!-- begin::Home for Superadmin Role -->
  <div v-if="isAuthenticated">
    <div class="row">
      <router-link
        v-for="(item, i) in homeItemsList"
        :key="i"
        :to="item.to"
        v-slot="{ href, navigate }"
        custom
      >
        <div class="col-lg-3 text-center p-6">
          <a :href="href" @click="navigate">
            <div
              class="d-flex flex-column justify-content-around align-items-center home-box"
            >
              <inline-svg class="home-icon" :src="item.icon" />
              <h3 class="home-text">
                {{ item.label }}
              </h3>
            </div>
          </a>
        </div>
      </router-link>
    </div>
  </div>
  <!-- end::Home for Superadmin Role -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeSuperadmin",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      homeItemsList: [
        {
          to: "/manager/myprofile",
          label: this.$i18n.t("MY_PROFILE.TITLE"),
          icon: "/media/icons/perfil-primary.svg"
        },
        {
          to: "/manager/senders",
          label: this.$i18n.t("MENU.SENDERS"),
          icon: "/media/icons/remi-primary.svg"
        },
        {
          to: "/manager/transporters",
          label: this.$i18n.t("MENU.TRANSPORTERS"),
          icon: "/media/icons/transportista-primary.svg"
        },
        {
          to: "/manager/users",
          label: this.$i18n.t("MENU.USERS"),
          icon: "/media/icons/usuarios-primary.svg"
        },
        {
          to: "/manager/shipmentsall",
          label: this.$i18n.t("MENU.SHIPMENTS"),
          icon: "/media/icons/envios-primary.svg"
        },
        {
          to: "/manager/subscriptions/types",
          label: this.$i18n.t("MENU.SUBSCRIPTION_TYPES"),
          icon: "/media/icons/subscripcion-primary.svg"
        },
        {
          to: "/manager/vehicles",
          label: this.$i18n.t("MENU.VEHICLES"),
          icon: "/media/icons/car-primary.svg"
        }
      ]
    };
  }
};
</script>
